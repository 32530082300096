export const defaultEnvVariables: Config = {
  production: false,
  API_URL: '',
  WS_URL: '',
  SSO: {
    enabled: true,
    issuer: '',
    clientId: '',
    discoveryDocumentEndpoint: '',
    useAccessToken: true
  }
}

export interface Config {
  production: boolean;
  API_URL: string;
  WS_URL: string;
  SSO: SsoConfig
}

export interface SsoConfig {
  enabled: boolean;
  issuer: string;
  clientId: string;
  discoveryDocumentEndpoint: string;
  useAccessToken: boolean;
}

