import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorage } from "angular-oauth2-oidc";

@Injectable({
  providedIn: "any",
})
export class AuthHttpClientService {
  constructor(
    private http: HttpClient,
    private oauth: OAuthStorage,
  ) {}

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(url, {
      headers: {Authorization: `Bearer ${this.oauth.getItem("access_token")}`},
    });
  }

  post<T>(url: string, body?: any): Observable<T> {
    return this.http.post<T>(url, body, {
      headers: {Authorization: `Bearer ${this.oauth.getItem("access_token")}`, 'Content-Type': 'application/json'},
    });
  }
}
